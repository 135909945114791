<template>
    <div class="report-booking-locations">
        <md-card>
            <md-card-header>
                <h4 style="margin:0">Booking Locations</h4>
            </md-card-header>
            <md-card-content>
                <div class="map"></div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
//https://developers.google.com/maps/documentation/javascript/get-api-key

import MarkerClusterer from "@google/markerclusterer";
export default {
    data() {
        return {
            locations: [],
            map: null
        };
    },
    methods: {
        async loadScript() {
            window.initMap = this.initMap;
            const api_key = (window.INJECT_ENV && window.INJECT_ENV.GOOGLE_MAP_API_KEY) || "AIzaSyCw305DNDiYvcb3Q2SHYagka_RV5fYAxyw";
            const script = `https://maps.googleapis.com/maps/api/js?key=${api_key}&callback=initMap`;

            let scripts = document.querySelectorAll(`script`);
            let existScript = [...scripts].find(s => s.src == script);
            console.info(existScript);
            if (existScript) {
                this.initMap();
            } else {
                let scriptEle = document.createElement("script");
                scriptEle.async = true;
                scriptEle.src = script;
                document.head.appendChild(scriptEle);
            }
        },
        async initMap() {
            let container = this.$el.querySelector(".map");
            let center = { lat: 40.7128, lng: -74.006 };
            let map = new google.maps.Map(container, { zoom: 5, center });
            this.map = map;

            await this.loadLocations();

            // let marker = new google.maps.Marker({ position: center, map: map });
        },
        async loadLocations() {
            let result = await this.$store.dispatch("crud/get", {
                api: "reports/booking-locations"
            });
            let locations = result.map(r => r._id).filter(a => a && /,/.test(a));
            this.locations = locations;
            console.info(locations);
            let markers = locations.map(l => {
                let position = { lat: +l.split(",")[0], lng: +l.split(",")[1] };
                return new google.maps.Marker({ position, map: this.map });
            });
            let markerCluster = new MarkerClusterer(this.map, markers, { imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m" });
        }
    },

    async mounted() {
        console.info("created");
        await this.loadScript();
    }
};
</script>

<style lang="less">
.report-booking-locations {
    .map {
        height: calc(~"100vh - 180px");
    }
}
</style>

